<template>
  <div>
    <div class="tbox">
      <el-row :gutter="10">
        <el-col :span="5">
          <doctors-list-itemtest :doctors-item="detail" class="now"></doctors-list-itemtest>
        </el-col>
        <el-col :span="13">
          <span class="vt1">{{ detail.infoTitle }}</span>
          <p></p>
          <!-- <div class="vttime">{{startTime}}</div> -->
          <!-- <span class="vname">视频内容</span> -->
          <span class="vcon">
            <el-row>
              <div :span="24" v-for="item in msg" :key="Math.random()">
                <span v-html="item"></span>
              </div>
            </el-row>
            <el-row style="margin-top:30px">
              <el-col>
                <div class="_xw2rajjf48q"></div>
              </el-col>
            </el-row>
          </span>
        </el-col>
        <el-col :span="6" class="bor">
          <el-row class="lrow">
            <el-col>
              <span class="cdoc"><img src="~assets/docw.svg" alt=""></span>
            </el-col>
            <el-col v-for="(item, index) in videos" :key="index" class="videosize">
              <small-videos-list-itemtest :videosItem="item" class="sv2"></small-videos-list-itemtest>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- </div> -->

    </div>
    <div class="mianbox">
      <div class="mian">
        <!-- <div class="guanggao">
          <div class="_xw2rajjf48q"></div>
        </div> -->
        <recommend-title :Title="'相关推荐'" :eTitle="'VOICE ANSWER'" :color="'#4263EB'" class="rectitle"></recommend-title>
        <div class="lv">
          <el-row style="margin-top:80px; width:950px" v-if="relaterecommended.length != 0">
            <el-col :span="24" v-for="project in relaterecommended" :key="project.pid" :offset="1" class="elhover">
              <long-videos-listtest :totop="true" :videos="project" class="now ql"></long-videos-listtest>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LongVideosList from 'components/content/videos/LongVideosList'
import LongVideosListtest from 'components/content/videos/LongVideosListtest'
// import SmallVideosList from 'components/content/videos/SmallVideosList'
import SmallVideosListItemtest from 'components/content/videos/SmallVideosListItemtest'
// import DoctorsList from 'components/content/doctors/DoctorsList'
import DoctorsListItemtest from 'components/content/doctors/DoctorsListItemtest'
import RecommendTitle from 'components/content/recommendtitle/RecommendTitle'
// import { Decrypt } from '@/util/EncryptionToDecrypt'
import { getMediadetailList } from 'network/getData'
// import formateDate from 'util/formateDate'
import subString from 'util/changeString'

export default {
  name: 'VideoDetail',
  components: {
    // DoctorsList,
    DoctorsListItemtest,
    // SmallVideosList,
    RecommendTitle,
    // LongVideosList,
    SmallVideosListItemtest,
    LongVideosListtest
  },
  data() {
    return {
      relaterecommended: [],
      // wavesurfer: null,
      detail: [],
      msg: [],
      videos: [],
      title: '文章',
      content: ''
    }
  },
  updated() {
    // document.querySelector('img').cssText({
    //   width: '100%'
    // })
    // $('.msgHtmlBox').find('p').css('color', 'blue');
  },
  created() {
    // const id = sessionStorage.getItem('id')
    this.getMediadetailListdata(this.$route.query.articleId, 'article')
  },
  mounted() {
    (window.slotbydup = window.slotbydup || []).push({
      id: 'u6745378',
      container: '_xw2rajjf48q',
      async: true
    })
  },
  watch: {
    $route: function (newVal) {
      // const id = sessionStorage.getItem('id')
      this.getMediadetailListdata(this.$route.query.articleId, 'article')
    },
    state: function (newVal) {
      console.log(newVal)
    }
  },
  // metaInfo () {
  //   return {
  //     title: this.title + '_瑞尔安心',
  //     meta: [
  //       { name: 'keywords', content: this.title },
  //       { name: 'description', content: this.content }
  //     ]
  //   }
  // },
  methods: {
    stripHtml(html) {
      return (new DOMParser().parseFromString(html, 'text/html')).body.innerHTML || ''
    },
    async getMediadetailListdata(infoId, type) {
      try {
        const result = await getMediadetailList(infoId, type, this.$route.query.t)
        this.videos = result.data.data
        this.detail = result.data.data.detail
        this.msg = JSON.parse(this.detail.content)
        this.videos = result.data.data.doctorWorks
        this.relatevideos = result.data.data.relatedContent
        this.relaterecommended = result.data.data.relatedScienceContent
        this.title = this.detail.infoTitle
        this.content = this.msg.join('').length > 100 ? this.msg.join('').slice(0, 100) : this.msg.join('').length
        // sessionStorage.setItem('keywords', this.detail.infoTitle)
        // sessionStorage.setItem('description', this.detail.content.subString(0, 100))
      } catch (error) {
        // console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.tbox {
  /* position: absolute; */
  width: 1200px;
  /* height: 751px; */
  /* left: 120px; */
  /* margin-left: 120px; */
  top: 100px;
  /* background: #FAFAFA; */
  margin: 0 auto;
  margin-bottom: 50px;
}

.doc {
  /* position: absolute; */
  width: 232px;
  height: 415px;
  left: 20px;
  top: 20px;
}

.lbox {
  /* position: absolute; */
  width: 302px;
  height: 458px;
  left: 898px;
  top: 26px;
  border: 1px solid #EAEDF0;
  box-sizing: border-box;
}

.cvideo,
.cdoc {
  /* position: absolute; */
  /* width: 96px;
  height: 28px;
  left: 23px;
  top: 15px; */
  font-family: "PangMenZhengDao-3";
  font-size: 24px;
  line-height: 28px;
  color: #262626;
  margin-left: 15px;
}

.sv1 {
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 61px; */
}

.sv2 {
  /* position: absolute; */
  width: 269px;
  height: 52px;
  left: 12px;
  /* top: 315px; */
}

.vt1 {
  /* position: absolute; */
  /* width: 336px; */
  height: 38px;
  left: 272px;
  top: 20px;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: #000000;
}

.vttime {
  /* position: absolute; */
  width: 174px;
  height: 24px;
  left: 272px;
  top: 68px;
  color: #8C8C8C;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.vname {
  /* position: absolute; */
  width: 580px;
  height: 28px;
  left: 272px;
  top: 459px;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}

.vcon {
  /* position: absolute; */
  width: 580px;
  /* height: 168px; */
  left: 272px;
  top: 112px;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}

.vcon .el-col {
  margin-top: 10px;
}

image {
  width: 100% !important;
}

.mianbox {
  /* position: absolute; */
  position: relative;
  width: 1200px;
  /* left: 120px; */
  /* top: 714px; */
  margin: 0 auto;
  margin-bottom: 200px;
}

.bor {
  border: 1px solid #EAEDF0;
  height: 458px;
}

.mian {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  /* position: absolute; */
  width: 1200px;
  /* left: 125px; */
  top: 0px;
}

.videosize {
  margin-top: 10px;
}

.lrow {
  margin-top: 21px;
  /* margin-bottom: 24px; */
}

.lv {
  /* margin-top: 100px; */
}
</style>
